.main_footer_container{
    height: 60px !important;
    z-index: 20;
    box-shadow: 4px 1px 3px 0px black;
    position: absolute;
    background-color: white;
    width: 100% !important;
    padding: 0px 10px 0px 10px ;
}
@media screen and (max-width: 900px) {
  .main_footer_container{
      height: 70px !important;
  }
  .footer_icon_mail, .footer_icon_whattsup, .footer_icon_facebook{
    display: none;
  }
}

.footer_right_container, .footer_contact_container{
    height: 100%;
}
.footer_privacy_policy{
    font-size: 1vw !important;
    cursor: pointer;
}
.footer_privacy_policy:hover{
    color: black;
}
.footer_icon_mail, .footer_icon_whattsup, .footer_icon_facebook{
    margin-right: 30px;
    height: 30px;
    width: 30px;
    cursor: pointer;
}
.footer_icon_mail:hover{
    color:red;
}
.footer_icon_whattsup:hover{
    color: #00E676;
}
.footer_icon_facebook:hover{
    color:#0866FF;
}
.footer_icon_href{
    color: black;
}