.line_container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.line{
    background-color: white;
    width: 100%;
    height: 4px;
}
.process_container{
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.process_container:hover .process_image{
    scale: 1.2;
}
.process_title_container{
    justify-content: center;
    align-items: center;
    height: 10%;
}
.process_title{
    color: white;   
}
.process_description_container{
    overflow: hidden;
    height: 90%;
}
.process_image_container{
    display: flex;
    justify-content: center;
    height: 65%;
    overflow: hidden;
    border-bottom: 1px solid white;
}
.process_image{
    width: 100%;
    height: 100% !important;
    overflow: hidden;
    transition: .5s;
}
.process_text_container{
    height: 35% !important;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    background-color: rgb(0, 0, 0, 0.6);
}
@media screen and (max-width:900px) {
    .main_container_right{
        width: 45vw !important;
        height: 30vh !important;
    }
    .main_container_left{
        width: 45vw !important;
        height: 30vh !important;
        left: calc(5% - 2px);
    }
    .line_container{
        display: none;
    }
    .line{
        display: none;
    }
    .process_image_container{
        height: 100%;
        width: 100% !important;
        border-bottom: none;
    }
    .process_text_container{
        display: block;
        position: absolute;
        z-index: 22;
        top: 0;
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
        backdrop-filter: blur(1px) !important;  
    }
    .process_description_container{
        height: 100%;
        width: 100%;
        position: relative;
    }
}