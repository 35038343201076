.button{
    color: black;
    cursor: pointer;
    border: 1px solid black;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right:15px;
    transition: .3s ease;
}
.button:hover{
    border: white 1px solid;
    background-color: black;
    color: white;
}
.button_text{
    font-family: "Cinzel", serif !important;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 600 !important;
    letter-spacing: .1em !important;
    text-align: center;
    transition: font-size .5s;
}