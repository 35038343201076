.main_container{
    width: 80%;
}
.image{
    width: 100%;
}
.hidden_product_info_container{
    position: absolute;
    width: 100%;
    height: 100%;
    transition: background-color 0.2s, opacity 0.2s ease;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hidden_product_info_container:hover{
    background-color: rgba(255, 255, 255, 0.5);
    display: flex !important;
    opacity: 1;
}