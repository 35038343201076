.main_contact_container{
    background-color: rgb(0, 0,  0, 0.9);
    height: 100vh;
}
.contact_google_maps_item{
    width: 100%;
    height: 75%;
}
.contact_info_container{
    overflow: hidden;
}