.title_active{
    animation-name:appear_animation !important;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
@keyframes appear_animation {
    from{opacity: 0;}
    to{
        opacity:1;
        transform: translateY(0);
        transform: translateX(0);
    }
}

#diamonds_product_section_image{
    width: 100%;
    max-width: 600px;
}

#diamonds_products_section{
    opacity: 0;
    transition: 1.5s;
}
.diamonds_products_section_active{
    opacity: 1 !important;
}