.main_container{
  overflow: hidden;
}

.main_image_container{
  width: 100vw;
  height: 100vh;
  position: fixed;
  
}
.about_main_line{
  position: fixed;
  right: calc(50% - 2px);
  height: calc(100vh - 120px);
  top: 120px;
  z-index: 20;
  background-color: white;
  width: 4px;
}
.about_main_line_progress{
  position: fixed;
  right: calc(50% - 2px);
  top: 120px;
  z-index: 20;
  background-color: gold;
  width: 4px;
}
@media screen and (max-width: 900px){
  .about_main_line{
    display: none;
  }
  .about_main_line_progress{
    display: none;
  }
}