.section_title_after::after{
    width: 3rem;
    margin-top: 1rem;
}
.section_title_after::after{
    content: "";
    display: block;
    width: 4rem;
    height: 1px;
    margin-right: auto;
    margin-left: auto;
    background-color: currentColor;
}
.section_title_header{
    font-family: "Cinzel", serif !important;
    font-optical-sizing: auto;
    font-weight:500 !important;
    font-style: normal;
    text-align: center;
    transition: font-size .5s;
}
.section_title_text{
    font-family: "Cinzel", serif !important;
    font-optical-sizing: auto;
    font-weight:500 !important;
    font-style: normal;
    text-align: center;
    transition: font-size .5s;
}