#product_modal_main_container{
    position:fixed;
    background-color: white;
    height: 100%;
    width: 100%;
    z-index: 2000;
    animation: show_product_modal .2s ease forwards;
    display: flex;
    float: left;
}
@keyframes show_product_modal {
    from{scale: 0; opacity: 0;}
    to{scale: 1; opacity: 1;}
}
.product_modal_close_menu_icon{
    background-color:rgba(255, 255, 255, .5);
    position: absolute;
    top: 15px;
    right: 15px;
}
.product_modal_close_menu_icon:hover{
    color: red;
  }
.product_modal_main_image{
    max-width: 100%;
    height: auto;
}
.product_modal_main_name{
    font-size: 50px !important;
    font-family: "Cinzel", serif !important;
    font-optical-sizing: auto;
    font-weight:500 !important;
    font-style: normal;
}
.product_modal_main_name::after{
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    margin-right: auto;
    margin-left: auto;
    background-color: currentColor;
}
.product_modal_main_image{
    width: 100%;
}