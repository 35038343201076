:root {
    --swiper-theme-color: gray !important;
}
.main_homepage_slider{
    width: 100%;
}
.slide{
width: 100%;
}
.text_container_1{
position: absolute;
color: white;
top: 25%;
right: 5%;
cursor: pointer;
font-family: "Cinzel", serif !important;
font-optical-sizing: auto;
font-weight:400 ;
font-style: normal;
}
.header_text_1{
cursor: pointer;
font-family: "Cinzel", serif !important;
font-optical-sizing: auto;
font-weight:500 !important;
transition: font-size 1s;
}
.text_1{

text-align: center;
cursor: pointer;
font-family: "Cinzel", serif !important;
font-optical-sizing: auto;
font-weight:400 ;
transition: font-size 1s;
}

.text_1::after{
    content: "";
    display: block;
    width: 120px;
    height: 1px;
    margin-right: auto;
    margin-left: auto;
    background-color: currentColor;
    transition: width .2s ease;
}

.text_container_1:hover .text_1::after{
    width: 0;
}
.text_2::after{
    content: "";
    display: block;
    width: 120px;
    height: 1px;
    margin-right: auto;
    margin-left: auto;
    background-color: currentColor;
    transition: width .2s ease;
}

.text_container_2:hover .text_2::after{
    width: 0;
}

.text_container_2{
position: absolute;
color: white;
bottom: 20%;
left: 10%;
cursor: pointer;
font-family: "Cinzel", serif !important;
font-optical-sizing: auto;
font-weight:400 ;
font-style: normal;
}
.header_text_2{
cursor: pointer;
font-family: "Cinzel", serif !important;
font-optical-sizing: auto;
font-weight:500 !important;
font-size: 20px !important;
transition: font-size 1s;
}
.text_2{
font-size: 16px !important;
text-align: center;
cursor: pointer;
font-family: "Cinzel", serif !important;
font-optical-sizing: auto;
font-weight:400 ;
transition: font-size 1s;
}
.text_container_2:hover .text_2{
text-decoration: none;
}
.desktop_picture{
width: 100vw;
}
.mobile_picture{
display: none;
width: 100vw;
height: 130vw !important;
object-fit: cover;
}
@media screen and (max-width:600px) {
.desktop_picture{
    display: none;
    }
.mobile_picture{
    display: block;
    }
}
@media screen and (max-width:1200px) {
.text_container_1, .text_container_2 { top:85%; right:0; left: 0; text-align: center;}
}