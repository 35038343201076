.navbar_desktop_top{
    background-color:rgba(255, 255, 255, 0);
    color: white;
    box-shadow: 0px 0px 0px 0px rgba(66, 68, 90, 1);
    backdrop-filter: blur(0px) !important; 
}
.navbar_mobile_top{
    background-color:rgba(255, 255, 255, 0);
    color: white;
    box-shadow: 0px 0px 0px 0px rgba(66, 68, 90, 1);
    backdrop-filter: blur(0px) !important; 
}
.link_on_top::after{
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: white !important;
    transition: width .3s;
    width: 100%;
}
.navigation_link_chosen{
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    font-family: "Cinzel", serif !important;
    font-optical-sizing: auto;
    font-weight:400 !important;
    font-style: normal;
    transition: font-size .5s;
  }
  .navigation_link_chosen::after{
    content: '';
    display: flex;
    height: 2px;
    background: black;
    width: 100%;
    transition:.5s;
  }
.bigger_letters{
    height: 30px;
}
.bigger_letters::after{
    content: '';
    display: block;
    height: 2px;
    background: black;
    transform: translateY(-6px);
    width: 50%;
    transition: .3s;
}
.bigger_letters_active::after{
    width:60%;
    transform: translate(70%, -6px);
}
.bigger_letters_on_top::after{
    background: white !important;
  }
#navbar_desktop:hover #bigger_letters::after, #navbar_desktop:hover .navigation_link_chosen::after{
background: black !important;
}