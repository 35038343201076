.main_navbar_container{
    height: 120px;
    background-color:rgba(255, 255, 255, .5);
    position: fixed;
    top: 0;
    z-index: 200;
    transition: background-color .3s ease-out;
    color: black;
    box-shadow: 0px 1px 5px 0px rgba(66, 68, 90, 1);
    backdrop-filter: blur(7px); 
  }
.main_navbar_container:hover{
  background-color: rgb(255, 255, 255, 1);
  color: black;
}

/* LANGUAGE SELECTION SECTION */

.select_language_section{
  padding: 20px;
}
.country_flag_icon_container{
  border-radius: 20px;
}
.country_flag_icon{
  height: 20px;
  border-radius: 20px;
  display: none;
  transition: box-shadow .2s, transform .2s ease;
}
.country_flag_icon:hover{
  transform: translateY(-2px);
  box-shadow: black 0px 0px 10px 2px;
  cursor: pointer;
}
.country_flag_text_container{
  padding-left: 10px;
}
.country_flag_text{
  cursor: pointer;
  font-family: "Cinzel", serif !important;
  font-optical-sizing: auto;
  font-weight:400 ;
  font-style: normal;
}


.country_flag_icon_chosen{
  opacity:0;
  height: 25px;
  border: 1px solid gray;
  border-radius: 20px;
  animation-name: change_language;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  cursor: pointer;
  transition: scale .2s ease;
}

@keyframes change_language {
  from{opacity: 0;}
  to{opacity: 1;}
}

.country_flag_icon_chosen:hover{
  scale: 0.9;
}


/* NAVBAR ON MOBILE SECTION */

.main_navbar_container_mobile{
  position: fixed;
  top: 0;
  z-index: 200;
  height: 70px;
  transition: background-color .4s ease-out;
  background-color:rgba(255, 255, 255);
  box-shadow: 0px 1px 5px 0px rgba(66, 68, 90, 1);
  display: none !important;
  color: black;
}
.main_navbar_container_mobile:hover{
  background-color: rgb(255, 255, 255);
  color: black;
}
.navbar_logo_container_mobile{
  height: 100%;
}
.navbar_logo_mobile{
  height: 50%;
  padding-top: 15px;
  padding-left: 5px;
  cursor: pointer;
}
.navibar_icon_container_mobile{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}
.main_navbar_container_mobile:hover .menu_icon{
  color: black !important;
}
.navbar_menu_links_container{
  margin-top: 20px;
}
.menu_icon{
  padding-top: 15px;
  cursor: pointer;
}
.navbar_menu_logo{
  height: 35px;
  padding-top: 15px;
  padding-left: 5px;
  cursor: pointer;
}

.navbar_menu_container{
  position: fixed;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 2000;
  transform: translateX(100%);
}
.navbar_menu_container_active{
  position: fixed;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 2000;
  transform: translateX(100%);
  animation-name: menu_show_animate;
  animation-duration: .3s;
  animation-fill-mode: forwards;
}
.close_menu_icon:hover{
  color: red;
}
@keyframes menu_show_animate {
  from{transform: translateX(100%);}
  to{transform: translateY(0%);}
}


@media screen and (max-width:900px) {
  .main_navbar_container_mobile{
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
  }
  .main_navbar_container{
    display: none !important;
  }
}

/* LOGO AND NAVIGATION SECTION */

.logo_and_navigation_section_image_container{
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo_and_navigation_section_image{
  cursor: pointer;
  width: 250px;
}
.logo_and_navigation_section_links_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.navigation_container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
}
.navigation_link{
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-family: "Cinzel", serif !important;
  font-optical-sizing: auto;
  font-weight:400 !important;
  font-style: normal;
  transition: font-size .5s;
}
.navigation_link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: black;
  transition: width .3s;
}
.navigation_link:hover::after {
  width: 100%;
}
.navigation_link_mobile{
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-family: "Cinzel", serif !important;
  font-optical-sizing: auto;
  font-weight:400 !important;
  font-style: normal;
  letter-spacing: 2px !important;
}
.navigation_link_mobile::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: black;
  transition: width .3s;
}
.navigation_link_mobile:hover::after {
  width: 100%;
}