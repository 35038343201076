.card_right{
    width: 45vw !important;
    height: 60vh !important;
    z-index: 21;
    position: relative;
    top: 150px;
    left: calc(55% + 2px);
    transition: left .5s, opacity 1s ease; 
    opacity: 0;
}
.right_card_activate{
    left: calc(50% + 2px);
    opacity: 1;
}
.card_left{
    width: 45vw !important;
    height: 60vh !important;
    z-index: 21;
    position: relative;
    top: 150px;
    left: calc(0% - 2px);
    transition: left .5s, opacity 1s ease; 
    opacity: 0;
}
.left_card_activate{
    left: calc(5% - 2px);
    opacity: 1;
}
@media screen and (max-width: 900px){
    .card_right{
        top: 100px;
        width: 100vw !important;
        height: 40vh !important;
        left: 50%;
        transition: left 2s ease-in-out, opacity 1s ease-in-out; 
        margin-bottom: 70px !important;
    }
    .right_card_activate{
        left:0%;
        opacity: 1;
    }
    .card_left{
        top: 100px;
        width: 100vw !important;
        height: 40vh !important;
        left: -50%;
        transition: left 2s ease-in-out, opacity 1s ease-in-out;
        margin-bottom: 70px !important; 
    }
    .left_card_activate{
        left:0;
        opacity: 1;
    }
  }